import {formActions} from '@/enums/formActions'

const initState = () => ({
  itemGroups: [],
})

export const state = initState

export const getters = {
  getItemGroups: (state) => state.itemGroups,
}

export const mutations = {
  setItemGroups(state, {itemGroups}) {
    state.itemGroups = itemGroups
  },
}

export const actions = {
  async fetchItemGroups({commit}) {
    return await this.$axios.$get('webpos/itemgroup/').then((result) => {
      return result.map((element) => {
        return {text: element.n, value: element.id}
      })
    })
  },

  async fetchItemTypesByAdminSettingCode({commit}, adminSettingCode) {
      try {
        return await this.$axios.$get(`webpos/itemgroup/get-item-types/${adminSettingCode}`);
      } catch(error){
        console.error('Error fetching item types by item groups,', error);
        this.$toast.error('Error fetching item types by item groups');
      }
  },
  async fetchAcceptedItemGroupsForCustomOrders() {
    let acceptedItemGroups = [];
    try {
      const result = await this.$axios.$get(`webpos/itemgroup/get-accepted-item-groups-for-co`);
      if(result && !result.hasErrors){
        acceptedItemGroups =  result.value;
      }
      else{
        this.$toast.error('Error while getting accepted item groups for custom orders');
      }
    }
    catch(error){
      this.$toast.error('Error while getting accepted item groups for custom orders');
    }
    return acceptedItemGroups;
  },
  async getItemTypeListItemsByItemGroupID({commit}, itemGroupID){
    let itemTypes = [];
    try{
      const result = await  this.$axios.$get(`/webpos/itemgroup/${itemGroupID}/itemtypes/list-items`);
      if(result && !result.hasErrors && result.value){
        itemTypes = result.value;
      }
      else{
        this.$toast.error('Error while getting item types by item group id');
      }
    }
    catch(e){
      this.$toast.error('Error while getting item types by item group id');
    }
    return itemTypes;
  },
  async getCustomAttributesByItemGroupID({commit}, itemGroupID){
    let customAttributes = [];
    try{
      const result = await this.$axios.$get(`/webpos/itemgroup/${itemGroupID}/custom-attributes`);
      if(result && !result.hasErrors){
        customAttributes = result.value;
      }
      else{
        this.$toast.error('Error while getting item types by item group id');
      }
    }
    catch(e){
      this.$toast.error('Error while getting custom attributes by item group id');
    }
    return customAttributes;
  },

  async getItemGroups({commit}, filter) {
    let itemGroups = [];
    try{
      const result = await this.$axios.$post('webpos/itemgroup/', filter);

      if(result && result.apiMessages.hasErrors && result.apiMessages.serverErrors.length > 0){
        this.$toast.error('Error while getting item groups');
      }
      else {
         itemGroups = result.value;
      }
    }
    catch {
      this.$toast.error('Error while getting item groups');
    }

    return itemGroups;

  },
}
