import {mapFilters} from "@/utils/helpers";

const initState = () => ({
})

export const state = initState

export const getters = {
}

export const mutations = {
}

export const actions = {
  async createCustomJobOrder({commit}, customJobOrder){
    let insertedCustomJobOrder = null
    try{
      let result = await this.$axios.$post(`/webpos/custom-job-order/create`, customJobOrder);
      if(result && result.apiMessages.hasErrors && result.apiMessages.serverErrors.length > 0){
        this.$toast.error(result.apiMessages.serverErrors[0]);
      }
      else if (result.value){
        insertedCustomJobOrder = result.value;
      }

    }
    catch(error){
      if(error.response && error.response.data && error.response.data.Message){
        this.$toast.error(error.response.data.Message);
        return;
      }
      this.$toast.error("Error while creating custom job order");
    }
    return insertedCustomJobOrder;
  },

  async createCustomPriceQuote({commit}, customPriceQuote){
    let insertedCustomPriceQuote = null
    try{
      let result = await this.$axios.$post(`/webpos/custom-price-quote/create`, customPriceQuote);
      if(result && result.apiMessages.hasErrors && result.apiMessages.serverErrors.length > 0){
        this.$toast.error(result.apiMessages.serverErrors[0]);
      }
      else if (result.value){
        this.$toast.success("Custom price quote created successfully");
        insertedCustomPriceQuote = result.value;
      }

    }
    catch(error){
      if(error.response && error.response.data && error.response.data.Message){
        this.$toast.error(error.response.data.Message);
        return;
      }
      this.$toast.error("Error while creating custom price quote");
    }
    return insertedCustomPriceQuote;
  },

  async fetchCustomOrders({commit, state}, {pagingInfo, filters, showClosedOrders}){
    const url = `/webpos/custom-order/`;
    let customOrders = [];

    let filter = mapFilters(filters, pagingInfo.pageNo, pagingInfo.pageSize, pagingInfo.sortBy, pagingInfo.sortAsc);
    filter.showClosedOrders = showClosedOrders;
    const result = await this.$axios.$post(url, filter);

    if(result && !result.hasErrors){
      customOrders = result;
    }
    else{
      this.$toast.error('Error while getting custom orders')
    }
    return customOrders;
  },
}
