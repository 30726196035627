import {jobTypeCodes} from '@/enums/jobTypeCodes'
import {jobSubtypeCodes} from "@/enums/jobSubtypeCodes";
import {dashboardElements} from "@/enums/dashboardElements";
import {filterOptions} from "@/enums/filterOptions";
import {salesStatsGroupBy} from "@/enums/salesStatsGroupBy";

const initState = () => ({
  dashboard: [
    { type: dashboardElements.SALES_ORDERS, data: [], summaries: []},
    { type: dashboardElements.TOTAL_SALES, data: [['Date','Amount']]},
    { type: dashboardElements.SALES_AMOUNT, data: [ ['Date', 'Amount']]},
    { type: dashboardElements.DAILY_SALES, data: [['Date', 'Amount']]},
    { type: dashboardElements.MONTHLY_SALES, data: [['Date', 'Amount']]},
    { type: dashboardElements.LAYAWAYS, data: [], summaries: []}
  ],
})

export const state = initState

export const mutations = {
  setSalesOrderSummaries(state, {salesOrderSummaries}) {
    state.dashboard.find(d=> d.type === dashboardElements.SALES_ORDERS).summaries = salesOrderSummaries
  },
  setLayawayOrderSummaries(state, {layawayOrderSummaries}) {
    state.dashboard.find(d=> d.type === dashboardElements.LAYAWAYS).summaries = layawayOrderSummaries
  },
  setSalesStats(state,{sales, dashboardElement, groupBy, filterOption,selectedDate}){
    if (sales) {
      state.dashboard.find(d => d.type === dashboardElement).data = [['Date','Amount']]

      let date = new Date();
      let currDay
      let lastDay
      let amount = 0

      if (filterOption === filterOptions.TODAY || filterOption === filterOptions.DAY){

        let startTime = new Date(date.getFullYear(), date.getMonth(),date.getDate(), 0)
        let endTime = new Date(date.getFullYear(), date.getMonth(),date.getDate(), 23)

        for (let i = 0; startTime<=endTime;i++){
          let hourFormatted = startTime.toLocaleString("en-EN",{hour: "numeric"})

          if (dashboardElement === dashboardElements.TOTAL_SALES){
            amount = sales.filter((s) => new Date(s.date).getHours() === startTime.getHours())[0]?.itemCount
          }else{
            amount = sales.filter((s) => new Date(s.date).getHours() === startTime.getHours())[0]?.price
          }

          state.dashboard
            .find(d => d.type === dashboardElement)
            .data
            .push([ hourFormatted
              ,amount ? amount : 0])
          startTime.setHours(startTime.getHours() + 1);

        }

        return
      }

      if (filterOption === filterOptions.MONTH) {
        date = selectedDate ? new Date(selectedDate) : date
        currDay = new Date(date.getFullYear(), date.getMonth(), 1)
        lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
      }
      if (filterOption === filterOptions.WEEK){
        currDay = new Date(date.setDate(date.getDate() - date.getDay()));
        lastDay = new Date(date.setDate(date.getDate() - date.getDay() + 6));

      }

        for (let i = 0; currDay<=lastDay;i++){
          let formatted = currDay.toLocaleDateString("en-EN", {day: "numeric", month: "short"})

          if (dashboardElement === dashboardElements.TOTAL_SALES){
            amount = sales.filter((s) => new Date(s.date).toLocaleDateString("en-EN", {day: "numeric", month: "short"}) === formatted)[0]?.itemCount
          }else{
            amount = sales.filter((s) => new Date(s.date).toLocaleDateString("en-EN", {day: "numeric", month: "short"}) === formatted)[0]?.price
          }

          state.dashboard
            .find(d => d.type === dashboardElement)
            .data
            .push([ formatted
              ,amount ? amount : 0])
          currDay.setDate(currDay.getDate()+1)
        }

    }
  }
}

export const actions = {
  async getOrders({commit}, {filters, jobSubtypeCode}) {
    try{
      let url = `/webpos/dashboard/orders/?subTypeCode=${jobSubtypeCode}`
      if(filters && filters.fromDate && filters.dateTo){
        url =`${url}&fromDate=${filters.fromDate}&dateTo=${filters.dateTo}`
      }
      if(filters.storeID > 0){
        url =`${url}&storeID=${filters.storeID}`
      }
      const result = await this.$axios.$get(url)
      if(result && !result.hasErrors){
        return result.value
      }
      else{
        this.$toast.error("Error while getting orders!");
        return []
      }
    }catch(ex) {
      this.$toast.error("Error while getting orders!");
    }
  },

  async getOrderStatusSummaries({commit}, {dashboardElement, storeID}) {
    try{
      const orderSubType = dashboardElement === dashboardElements.SALES_ORDERS ? jobSubtypeCodes.POSSalesOrder : jobSubtypeCodes.LayawayOrder
      let url = `/webpos/dashboard/orders-status-summary/?subtypeCode=${orderSubType}`
      if(storeID > 0){
        url =`${url}&storeID=${storeID}`
      }

      const orderSummaries = await this.$axios.$get(url)

      if(orderSummaries && !orderSummaries.hasErrors){
        dashboardElement === dashboardElements.SALES_ORDERS
          ? commit('setSalesOrderSummaries', {salesOrderSummaries: orderSummaries.value})
          : commit('setLayawayOrderSummaries', {layawayOrderSummaries: orderSummaries.value})
      }
      else{
        this.$toast.error("Error while getting order status summaries!");
      }
    }
    catch(error){
      this.$toast.error("Error while getting order status summaries!");
    }
  },

  async getSalesStats({commit},{filters, dashboardElement,selectedDate}){
    try{
      let url = `/webpos/dashboard/sales/?entityTypeCode=${jobTypeCodes.SalesOrder}&fromDate=${filters.fromDate}&dateTo=${filters.dateTo}&groupBy=${filters.groupBy}`;
      if(filters.storeID > 0){
        url =`${url}&storeID=${filters.storeID}`
      }

      const salesStats = await this.$axios.get(url)

      if(salesStats && !salesStats.hasErrors){
        commit('setSalesStats', {sales: salesStats.data.value, dashboardElement: dashboardElement,
          groupBy: filters.groupBy, filterOption: filters.filterOption, selectedDate: selectedDate})
      }
      else{
        this.$toast.error("Error while getting sales stats");
      }
    }catch(ex){
      this.$toast.error('Error while getting sales stats');
    }
  }
}

